import React from "react"
// import ServicesScreen from "@screens/services/services"
// import { AllServices } from "@screens/services/components"

const Services = () => (
  <></>
  // <ServicesScreen>
  //   <AllServices />
  // </ServicesScreen>
)

export default Services
